import React, { FunctionComponent } from 'react'
import { Input } from 'antd'

import { InputData } from '../../../types'
import FormItem from './FormItem'

interface TextInputProps {
    data: InputData
}

const TextInput: FunctionComponent<TextInputProps> = ({ data, ...props }) => {
    return (
        <FormItem {...props} inputData={data}>
            <Input />
        </FormItem>
    )
}

export default TextInput
