import React, { FunctionComponent } from 'react'
import { Checkbox } from 'antd'
import { InputData } from '../../../../types'
import CheckboxLabel from './CheckboxLabel'

interface CheckboxInputProps {
    label?: string
    note?: string
    value?: boolean | undefined
    onChange?: (value: boolean | undefined) => void
    disabled: InputData['disabled']
}

const CheckboxInput: FunctionComponent<CheckboxInputProps> = ({ label, note, value, disabled, onChange }) => {
    return (
        <Checkbox
            disabled={disabled?.onCreate || disabled?.onUpdate || false}
            checked={value}
            onChange={(event) => {
                if (onChange) {
                    onChange(event.target.checked)
                }
            }}
        >
            <CheckboxLabel label={label} note={note} />
        </Checkbox>
    )
}

export default CheckboxInput
