import React, { FunctionComponent } from 'react'
import { InputData, UploadAsset } from '../../../../types'
import FormItem from '../FormItem'
import ImageUploader from './ImageUploader'

interface ImageUploaderItemProps {
    data: InputData
    uploadAsset?: UploadAsset
}

const ImageUploaderItem: FunctionComponent<ImageUploaderItemProps> = ({ data, uploadAsset, ...props }) => {
    return (
        <FormItem {...props} inputData={data}>
            <ImageUploader uploadAsset={uploadAsset} />
        </FormItem>
    )
}

export default ImageUploaderItem
