import React, { FunctionComponent, useState, useRef, useCallback, useEffect } from 'react'
import { Form } from 'antd'
import { InputData, InputValidator } from '../../../../types'
import ItemLabel from '../FormItem/ItemLabel'

type ColLayout = { span?: number; offset?: number }

type AsyncValidationProps = {
    inputData: InputData
    labelCol?: ColLayout
    wrapperCol?: ColLayout
    isUpdate?: boolean
    hideLable?: boolean
    inputValidator?: InputValidator
    readOnly?: boolean
    disabled?: boolean
}

type FormRules = Exclude<React.ComponentProps<typeof Form.Item>['rules'], undefined>

const AsyncValidationFormItem: FunctionComponent<AsyncValidationProps> = ({
    inputData,
    inputValidator = () => ({ validValue: false, errorMessage: '' }),
    hideLable = false,
    readOnly,
    disabled,
    children,
    ...props
}) => {
    const [validating, setValidating] = useState(false)
    const [formRules, setFormRules] = useState<FormRules>([])
    const fetchRef = useRef(0)

    const waitForLastEvent = () => {
        return new Promise((resolve) => {
            const validationTimeout = 800
            setTimeout(resolve, validationTimeout)
        })
    }
    const valueMatchValidator = useCallback(
        () => ({
            async validator(_: unknown, value: unknown) {
                setValidating(true)
                fetchRef.current += 1
                const fetchId = fetchRef.current

                await waitForLastEvent()

                if (fetchId !== fetchRef.current) {
                    return
                }

                const { validValue, errorMessage } = await inputValidator(
                    inputData.asyncValidatorRule?.validationUrl || '',
                    value
                )

                setValidating(false)

                if (validValue) {
                    return Promise.resolve(true)
                }

                return Promise.reject(errorMessage)
            }
        }),
        [inputValidator, inputData.asyncValidatorRule?.validationUrl]
    )

    useEffect(() => {
        if (readOnly || disabled) {
            return
        }
        setFormRules((prevState) => [...prevState, valueMatchValidator])
    }, [setFormRules, valueMatchValidator, readOnly, disabled])

    useEffect(() => {
        if (readOnly || disabled) {
            return
        }
        if (inputData.rules) {
            const inputDataRules = inputData.rules
            setFormRules((prevState) => [...prevState, ...inputDataRules])
        }
    }, [setFormRules, inputData.rules, readOnly, disabled])

    useEffect(() => {
        if (readOnly || disabled) {
            setFormRules([])
        }
    }, [readOnly, disabled])

    return (
        <Form.Item
            {...props}
            name={inputData.name}
            colon={false}
            label={hideLable ? '' : <ItemLabel labelData={inputData.label} note={inputData.note} />}
            rules={formRules}
            validateTrigger={inputData.asyncValidatorRule?.validateTrigger}
            hasFeedback
            validateStatus={validating ? 'validating' : undefined} // 'success', 'warning', 'error', 'validating'
            help={validating ? inputData?.asyncValidatorRule?.validatingMessage : undefined}
        >
            {children}
        </Form.Item>
    )
}

export default AsyncValidationFormItem
