import React, { FunctionComponent, Fragment } from 'react'
import { Form } from 'antd'
import { TripoCard } from '../../../../data-display'
import { FormData, InputData, DynamicInputsData, DynamicInputOptions } from '../../types'
import { InputsHelperProps } from '../interface'
import PlainInputsSwitch from './PlainInputsSwitch'
import ItemLabel from '../InputsGenerator/InputSwitch/FormItem/ItemLabel'
import { AddButton, DeleteButton } from './Buttons'
import FormInputs from '..'

interface DynamicInputsProps extends InputsHelperProps {
    data: FormData['inputs']
    dynamicInputs: DynamicInputsData
    parentGroupName?: string
    dynamicInputOptions?: DynamicInputOptions
}

const DynamicInputs: FunctionComponent<DynamicInputsProps> = ({
    data,
    dynamicInputs,
    parentGroupName,
    getFieldValue,
    getSelectOptions,
    dynamicInputOptions
}) => {
    const { groupName, buttonLabels } = dynamicInputs
    const generateListName = (currentName: string, parentName?: string) => {
        if (typeof parentName === 'undefined') {
            return currentName
        }
        return [parentName, currentName]
    }
    return (
        <Form.List name={generateListName(groupName, parentGroupName)}>
            {(fields, { add, remove }) => (
                <Fragment>
                    {fields.map(({ key, name, ...restField }) => (
                        <TripoCard className="providers-form-list" key={key}>
                            {data.map((inputData, ind) => {
                                if ('dynamicInputs' in inputData) {
                                    return (
                                        <FormInputs
                                            key={ind}
                                            {...{
                                                parentGroupName: name
                                            }}
                                            getSelectOptions={getSelectOptions}
                                            formData={[inputData]}
                                            getFieldValue={getFieldValue}
                                        />
                                    )
                                }
                                const validInputData = (inputData as InputData) || {}
                                return (
                                    <Form.Item
                                        {...restField}
                                        key={ind}
                                        colon={false}
                                        name={[name, validInputData.name]}
                                        fieldKey={[key, validInputData.name]}
                                        label={
                                            <ItemLabel labelData={validInputData.label} note={validInputData.note} />
                                        }
                                        rules={validInputData?.rules}
                                    >
                                        <PlainInputsSwitch
                                            data={validInputData}
                                            type={validInputData.type}
                                            getFieldValue={getFieldValue}
                                            getSelectOptions={getSelectOptions}
                                            groupName={groupName}
                                            fieldKey={key}
                                            dynamicInputOptions={dynamicInputOptions}
                                        />
                                    </Form.Item>
                                )
                            })}
                            <div className="delete-provider">
                                <DeleteButton
                                    text={buttonLabels.remove}
                                    remove={() => remove(name)}
                                    groupName={groupName}
                                    fieldKey={key}
                                    dynamicInputOptions={dynamicInputOptions}
                                />
                            </div>
                        </TripoCard>
                    ))}
                    <Form.Item>
                        <AddButton text={buttonLabels.add} add={add} />
                    </Form.Item>
                </Fragment>
            )}
        </Form.List>
    )
}

export default DynamicInputs
