import React, { FunctionComponent } from 'react'
import { InputData } from '../../../../types'
import { InputsHelperProps } from '../../../interface'
import FormItem from '../FormItem'
import SimpleWysiwyg from './SimpleWysiwyg'

interface SimpleWysiwygItemProps extends InputsHelperProps {
    data: InputData
}

const SimpleWysiwygItem: FunctionComponent<SimpleWysiwygItemProps> = ({ data, wysiwygFactory, ...props }) => {
    return (
        <FormItem {...props} inputData={data}>
            <SimpleWysiwyg wysiwygFactory={wysiwygFactory} />
        </FormItem>
    )
}

export default SimpleWysiwygItem
