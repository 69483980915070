import React, { FunctionComponent } from 'react'
import { InputData } from '../../../../types'
import FormItem from '../FormItem'
import SwitchInput from './SwitchInput'

interface SwitchInputItemProps {
    data: InputData
}

const CheckboxInputItem: FunctionComponent<SwitchInputItemProps> = ({ data, ...props }) => {
    return (
        <FormItem {...props} inputData={data} hideLable>
            <SwitchInput label={data?.label?.text} strongLabel={data?.label?.strong} description={data?.note} />
        </FormItem>
    )
}

export default CheckboxInputItem
