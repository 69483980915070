import React, { FunctionComponent } from 'react'
import { Switch } from 'antd'

interface SwitchInputProps {
    value?: boolean | undefined
    onChange?: (value: boolean, event: MouseEvent) => void
}

const SwitchInput: FunctionComponent<SwitchInputProps> = ({ value, onChange }) => {
    return (
        <Switch
            defaultChecked={value}
            onChange={(newValue, event) => {
                if (onChange) onChange(newValue, event)
            }}
        />
    )
}

export default SwitchInput
