import React, { FunctionComponent, Fragment } from 'react'
import { InputData, InputTypes } from '../../../types'
import { InputsHelperProps } from '../../interface'
import TextInput from './TextInput'
import UniqueTextInput from './UniqueTextInput'
import SelectInput from './SelectInput'
import PasswordInput from './PasswordInput'
import NumberInput from './NumberInput'
import ImageUploader from './ImageUploader'
import CheckboxInput from './CheckboxInput'
import DateInput from './DateInput'
import TimeInput from './TimeInput'
import SwitchInput from './SwitchInput'
import TextAreaInput from './TextAreaInput'
import SimpleWysiwyg from './SimpleWysiwyg'

interface InputSwitchProps extends InputsHelperProps {
    data: InputData
}

const InputSwitch: FunctionComponent<InputSwitchProps> = ({ data, ...props }) => {
    switch (data.type) {
        case InputTypes.text:
            return <TextInput {...props} data={data} />
        case InputTypes.uniqueText:
            return <UniqueTextInput {...props} data={data} />
        case InputTypes.select:
            return <SelectInput {...props} data={data} />
        case InputTypes.password:
            return <PasswordInput {...props} data={data} />
        case InputTypes.number:
            return <NumberInput {...props} data={data} />
        case InputTypes.image:
            return <ImageUploader {...props} data={data} />
        case InputTypes.checkbox:
            return <CheckboxInput {...props} data={data} />
        case InputTypes.datepicker:
            return <DateInput {...props} data={data} />
        case InputTypes.timepicker:
            return <TimeInput {...props} data={data} />
        case InputTypes.switch:
            return <SwitchInput {...props} data={data} />
        case InputTypes.textArea:
            return <TextAreaInput {...props} data={data} />
        case InputTypes.wysiwyg:
            return <SimpleWysiwyg {...props} data={data} />

        default:
            return <Fragment></Fragment>
    }
}

export default InputSwitch
