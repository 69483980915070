import React, { FunctionComponent, useEffect, useState } from 'react'
import { Input } from 'antd'
import { DynamicInputOptions } from '../../../types'

interface TextInputProps {
    name: string
    groupName: string
    fieldKey: number
    dynamicInputOptions?: DynamicInputOptions
}

const TextInput: FunctionComponent<TextInputProps> = ({ name, groupName, fieldKey, dynamicInputOptions, ...props }) => {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (!name) return

        const disableInputByGroup = dynamicInputOptions?.disableInputByGroup
        if (!disableInputByGroup) return

        const disableInputList = disableInputByGroup[groupName]
        if (!disableInputList) return

        const nameIncludedInDisableInputList = disableInputList.name.find((inputName) => inputName === name)
        if (!nameIncludedInDisableInputList) return

        setDisabled(fieldKey <= disableInputList.index)
    }, [name, groupName, fieldKey, dynamicInputOptions])

    return <Input {...props} disabled={disabled} />
}

export default TextInput
