import React, { FunctionComponent } from 'react'
import { Input } from 'antd'

import { InputData } from '../../../types'
import AsyncValidationFormItem from './AsyncValidationFormItem'
import useInputControlProps from './useInputControlProps'

interface UniqueTextInputProps {
    data: InputData
    isUpdate?: boolean
}

const UniqueTextInput: FunctionComponent<UniqueTextInputProps> = ({ data, ...props }) => {
    const [{ readOnly, disabled }] = useInputControlProps(data, props.isUpdate)

    return (
        <AsyncValidationFormItem {...props} inputData={data} readOnly={readOnly} disabled={disabled}>
            <Input readOnly={readOnly} disabled={disabled} />
        </AsyncValidationFormItem>
    )
}

export default UniqueTextInput
