import React, { FunctionComponent } from 'react'
import { InputNumber } from 'antd'
import { InputData } from '../../../types'
import FormItem from './FormItem'

interface PasswordInputProps {
    data: InputData
}

const NumberInput: FunctionComponent<PasswordInputProps> = ({ data, ...props }) => {
    return (
        <FormItem {...props} inputData={data}>
            <InputNumber min={1} max={100} className="input-number" />
        </FormItem>
    )
}

export default NumberInput
