import React, { Fragment, FunctionComponent, useState, useEffect } from 'react'
import { PageHeader, List, Button } from 'antd'
import { TripoCard } from '../../data-display'
import { Icons, validateIconType } from '../../general'
import { ConfigData, ListData } from './type'
import CreateHeaderButton from './CreateHeaderButton'
import PaginatedListTitle from './PaginatedListTitle'
import TripoInjectHTML from '../../utils/TripoInjectHTML'

interface PaginatedListProps {
    listData: ListData
    configData?: ConfigData
    loading: boolean
    className?: string
    customRenderItem?: (data: Record<string, string | boolean | undefined>) => JSX.Element
    handleNavigationClick: (to: string) => void
}

const PaginatedList: FunctionComponent<PaginatedListProps> = ({
    listData,
    configData,
    loading,
    className,
    customRenderItem,
    handleNavigationClick
}) => {
    const [filteredListData, setFilteredListData] = useState<ListData>([])
    const [filterValue, setFilterValue] = useState<string>('')

    useEffect(() => {
        setFilteredListData(
            listData.filter(({ name, filterText }) =>
                (filterText || name)?.toLocaleLowerCase?.()?.includes?.(filterValue?.toLocaleLowerCase?.())
            )
        )
    }, [filterValue, listData])

    const defaultRenderItem = ({ name, id }: { name: string; id: string; active?: boolean | undefined }) => (
        <List.Item
            actions={[
                <Button
                    type="link"
                    key="list-loadmore-edit"
                    onClick={() => {
                        handleNavigationClick(`/update/${id}`)
                    }}
                >
                    <Icons icon={validateIconType('edit')} />
                    {configData?.list?.button}
                </Button>
            ]}
        >
            <List.Item.Meta title={name} />
        </List.Item>
    )

    return (
        <Fragment>
            <PageHeader
                title={configData?.pageHeader?.title}
                extra={[
                    <CreateHeaderButton
                        key="create-header-button"
                        text={configData?.pageHeader?.button}
                        handleNavigationClick={handleNavigationClick}
                    />
                ]}
            />
            {configData?.pageHeader?.text && (
                <TripoInjectHTML
                    text={configData?.pageHeader?.text}
                    style={{ paddingLeft: '12px', paddingBottom: '20px' }}
                />
            )}

            <TripoCard
                title={
                    <PaginatedListTitle
                        title={configData?.list?.title}
                        placeholder={configData?.list?.searchPlaceholder}
                        onFilterChange={setFilterValue}
                    />
                }
            >
                <List
                    loading={loading}
                    className={className}
                    itemLayout="horizontal"
                    dataSource={filteredListData}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '30']
                    }}
                    renderItem={customRenderItem || defaultRenderItem}
                />
            </TripoCard>
        </Fragment>
    )
}

export default PaginatedList
