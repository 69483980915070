import React, { FunctionComponent } from 'react'
import { TimePicker } from 'antd'
import { InputData } from '../../../../types'
import FormItem from '../FormItem'

interface DateInputProps {
    data: InputData
}

const TimeInput: FunctionComponent<DateInputProps> = ({ data, ...props }) => {
    return (
        <FormItem {...props} inputData={data}>
            <TimePicker />
        </FormItem>
    )
}

export default TimeInput
