import React, { FunctionComponent, Fragment, useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { SpinnerInContainer } from '../../feedback'
import { generateInputNamesDictionary, parseFormValues } from './utils'
import { FormData, DynamicInputOptions, InputsInjectedHelperProps, getIfFormItemIsVisible } from './types'
import SubmitButton from './SubmitButton'
import FormInputs from './FormInputs'
import ConfirmationModal, { shouldShowConfirmationModal, formatConfirmationModalFields } from './ConfirmationModal'
import { ConfirmationModalData } from './ConfirmationModal/types'

interface FormGeneratorProps extends InputsInjectedHelperProps {
    submitButtonLabel: string
    initialValues?: Record<string, unknown>
    formData: FormData[]
    onFinish: (values: Record<string, unknown>) => void
    loading?: boolean
    disabled?: boolean
    confirmationModalData?: ConfirmationModalData
    mode?: string
    dynamicInputOptions?: DynamicInputOptions
    handleIsVisible?: getIfFormItemIsVisible
}

const FormGenerator: FunctionComponent<FormGeneratorProps> = ({
    submitButtonLabel,
    initialValues,
    formData,
    loading,
    disabled = false,
    onFinish,
    confirmationModalData,
    mode,
    dynamicInputOptions,
    children,
    ...props
}) => {
    const [inputNamesDictionary, setInputNamesDictionary] = useState<ReturnType<typeof generateInputNamesDictionary>>(
        {}
    )
    const [formSubmitData, setFormSubmitData] = useState<Record<string, unknown> | null>(null)
    const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)
    const [lastInputUpdated, setLastInputUpdated] = useState<Record<string, unknown>>()

    useEffect(() => {
        setInputNamesDictionary(generateInputNamesDictionary(formData))
    }, [formData])

    const [form] = Form.useForm()

    const handleFormSubmit = async (values: Record<string, unknown>) => {
        if (shouldShowConfirmationModal(mode, confirmationModalData)) {
            setFormSubmitData(values)
            setConfirmationModalVisible(true)
            return
        }
        onFinish(parseFormValues(values, inputNamesDictionary))
    }

    const closeConfirmationModal = () => {
        setFormSubmitData(null)
        setConfirmationModalVisible(false)
    }

    const handleConfirmationModalOnOk = async () => {
        if (formSubmitData) {
            onFinish(parseFormValues(formSubmitData, inputNamesDictionary))
        }
        closeConfirmationModal()
    }

    if (loading) return <SpinnerInContainer />

    const onValuesChange = (value: Record<string, unknown>) => setLastInputUpdated(value)

    return (
        <Fragment>
            <Form
                onFinish={handleFormSubmit}
                onValuesChange={onValuesChange}
                form={form}
                initialValues={initialValues}
                layout={'vertical'}
                className="form-generator-container"
                scrollToFirstError
            >
                <Row justify="start" gutter={[8, 24]} className="form-generator-row">
                    <FormInputs
                        formData={formData}
                        getFieldValue={form.getFieldValue}
                        lastInputUpdated={lastInputUpdated}
                        dynamicInputOptions={dynamicInputOptions}
                        {...props}
                    >
                        {children}
                    </FormInputs>
                    <Col span={24} className="form-generator-submit-col">
                        <SubmitButton label={submitButtonLabel} disabled={disabled} />
                    </Col>
                </Row>
            </Form>
            <ConfirmationModal
                isModalVisible={confirmationModalVisible}
                data={confirmationModalData}
                fields={formatConfirmationModalFields(formSubmitData, confirmationModalData?.validationFields)}
                onOk={handleConfirmationModalOnOk}
                onCancel={closeConfirmationModal}
            />
        </Fragment>
    )
}

export default FormGenerator
