import { useCallback, useEffect, useState } from 'react'
import { InputData } from '../../../types'

const useInputControlProps = (data: InputData, isUpdate?: boolean): [{ disabled: boolean; readOnly: boolean }] => {
    const [readOnly, setReadOnly] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)

    const handleControlProp = useCallback(
        (controlProp?: { onCreate?: boolean; onUpdate?: boolean }): boolean => {
            if (!isUpdate) {
                return !!controlProp?.onCreate
            }

            if (isUpdate) {
                return !!controlProp?.onUpdate
            }

            return false
        },
        [isUpdate]
    )

    useEffect(() => {
        setDisabled(handleControlProp(data.disabled))
    }, [data.disabled, handleControlProp])

    useEffect(() => {
        setReadOnly(handleControlProp(data.readOnly))
    }, [data.readOnly, handleControlProp])

    return [{ readOnly, disabled }]
}

export default useInputControlProps
