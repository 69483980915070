import React, { FunctionComponent } from 'react'
import { Modal } from 'antd'

interface ModalData {
    okText: string
    cancelText: string
    message: string
}

interface ConfirmationModalProps {
    isModalVisible: boolean
    onOk: () => void
    onCancel: () => void
    fields: { field: string; value: string }[]
    data?: ModalData
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
    isModalVisible,
    onOk,
    onCancel,
    fields,
    data
}) => (
    <Modal
        visible={isModalVisible}
        onOk={onOk}
        okText={data?.okText}
        onCancel={onCancel}
        cancelText={data?.cancelText}
        className="confirmation-modal"
    >
        <span>{data?.message}</span>
        <ul>
            {fields.map(({ field, value }, key) => (
                <li key={key}>
                    <span className="field-label">{field}</span>:<span className="field-value">{value}</span>
                </li>
            ))}
        </ul>
    </Modal>
)

export default ConfirmationModal
