import React, { FunctionComponent } from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

interface AddButtonProps {
    text: string
    add: () => void
}

const AddButton: FunctionComponent<AddButtonProps> = ({ text, add }) => {
    return (
        <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
            {text}
        </Button>
    )
}

export default AddButton
