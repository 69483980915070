import React, { FunctionComponent } from 'react'
import { Input } from 'antd'

interface SearchInputProps {
    placeholder?: string
    onChange: (value: string) => void
}

const SearchInput: FunctionComponent<SearchInputProps> = ({ placeholder, onChange }): JSX.Element => {
    return (
        <div className="paginated-list-search-input-container">
            <Input
                placeholder={placeholder}
                className="paginated-list-search-input"
                onChange={(event) => onChange(event.target.value)}
                allowClear
            />
        </div>
    )
}

export default SearchInput
