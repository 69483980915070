import React, { FunctionComponent, Fragment, useState, useEffect } from 'react'
import { Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { UploadAsset } from '../../../../types'

interface ImageUploaderProps {
    accept?: string
    id?: string
    onChange?: (payload: string | undefined) => void
    value?: string
    uploadAsset?: UploadAsset
}

type UploadFileStatus = 'error' | 'success' | 'done' | 'uploading' | 'removed'

interface UploadFile {
    uid: string
    size: number
    name: string
    type: string
    status?: UploadFileStatus
    url?: string
}

const ImageUploader: FunctionComponent<ImageUploaderProps> = ({ accept = 'image/*', onChange, value, uploadAsset }) => {
    const [assetUrl, setAssetUrl] = useState<string | undefined>(value)
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])

    const customRequest = async ({
        onSuccess,
        onError,
        file
    }: {
        onSuccess?: ((body: string, xhr: unknown) => void) | undefined
        onError?: ((body: unknown) => void) | undefined
        file: string | Blob | unknown
    }) => {
        if (!uploadAsset) return
        try {
            const { data } = await uploadAsset(file)
            if (data) {
                setAssetUrl(data.url)
                onChange && onChange(data.url)
                onSuccess?.('', null)
            } else {
                onError?.(null)
            }
        } catch (err) {
            onError?.(null)
        }
    }

    useEffect(() => {
        if (assetUrl) {
            // These values are only used for the preview of an uploaded image.
            setFileList([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    type: 'image/*',
                    url: assetUrl,
                    size: 100
                }
            ])
        } else {
            setFileList([])
        }
    }, [assetUrl])

    return (
        <Fragment>
            <Upload
                accept={accept}
                customRequest={customRequest}
                onRemove={() => {
                    setAssetUrl(undefined)
                }}
                onPreview={() => setPreviewVisible(true)}
                maxCount={1}
                listType="picture-card"
                fileList={fileList}
            >
                <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                </div>
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                <img alt="preview" style={{ width: '100%', paddingTop: '20px' }} src={assetUrl || ''} />
            </Modal>
        </Fragment>
    )
}

export default ImageUploader
