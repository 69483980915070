import React, { FunctionComponent } from 'react'
import { DatePicker } from 'antd'
import { InputData } from '../../../../types'
import FormItem from '../FormItem'

interface DateInputProps {
    data: InputData
}

const DateInput: FunctionComponent<DateInputProps> = ({ data, ...props }) => {
    return (
        <FormItem {...props} inputData={data}>
            <DatePicker />
        </FormItem>
    )
}

export default DateInput
