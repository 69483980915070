import React, { CSSProperties } from 'react'

/**
 * Component in charge of injecting HTML if needed
 * @type TripoInjectHTML
 * @property text can be either HTML to inject or plain text
 */
type TripoInjectHTMLProps = {
    text: string
    className?: string
    style?: CSSProperties
}

const TripoInjectHTML: React.FunctionComponent<TripoInjectHTMLProps> = ({ text, className, style = {} }) => {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: text
            }}
            className={className}
            style={style}
        ></div>
    )
}

export default TripoInjectHTML
