import React from 'react'
import { Spin } from 'antd'

const SpinnerInContainer = (): JSX.Element => (
    <div className="spinner-in-container">
        <Spin />
    </div>
)

export default SpinnerInContainer
