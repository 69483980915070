import React, { FunctionComponent, useState, useEffect } from 'react'
import { Button } from 'antd'
import { Icons, validateIconType } from '../../../../../general'
import { DynamicInputOptions } from '../../../types'

interface AddButtonProps {
    text: string
    remove: () => void
    groupName: string
    fieldKey: number
    dynamicInputOptions?: DynamicInputOptions
}

const DeleteButton: FunctionComponent<AddButtonProps> = ({
    text,
    remove,
    groupName,
    fieldKey,
    dynamicInputOptions
}) => {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        const disableDynamicInputRemove = dynamicInputOptions?.disableRemoveBtnByGroup
        if (!disableDynamicInputRemove) return
        const disableIndex = disableDynamicInputRemove[groupName]
        if (typeof disableIndex !== 'number') return
        setDisabled(fieldKey <= disableIndex)
    }, [dynamicInputOptions])

    return (
        <Button onClick={remove} style={{ textAlign: 'right' }} disabled={disabled}>
            <Icons icon={validateIconType('delete')} />
            {text}
        </Button>
    )
}

export default DeleteButton
