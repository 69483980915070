import React, { FunctionComponent, useState, useEffect } from 'react'
import { Form } from 'antd'

import ItemLabel from './ItemLabel'
import { getIfFormItemIsVisible, InputData } from '../../../../types'

type ColLayout = { span?: number; offset?: number }

type FormItemProps = {
    inputData: InputData
    labelCol?: ColLayout
    wrapperCol?: ColLayout
    isUpdate?: boolean
    hideLable?: boolean
    getFieldValue?: (id: string) => unknown
    handleIsVisible?: getIfFormItemIsVisible
}

type ConfigRules = NonNullable<InputData['rules']>
type ConfigValidator = NonNullable<InputData['validatorRule']>
type FormRules = React.ComponentProps<typeof Form.Item>['rules']

const FormItem: FunctionComponent<FormItemProps> = ({
    inputData,
    isUpdate,
    children,
    hideLable = false,
    getFieldValue,
    handleIsVisible,
    ...props
}) => {
    const [configRules, setConfigRules] = useState<ConfigRules>([])
    const [configValidator, setConfigValidator] = useState<ConfigValidator>()
    const [formRules, setFormRules] = useState<FormRules>([])
    const [isVisible, setIsVisible] = useState(inputData.isVisible || 'block')

    const inputRelated = inputData.inputRelated && getFieldValue && getFieldValue(inputData.inputRelated)

    useEffect(() => {
        async function fetchData() {
            if (inputRelated && inputData.url && handleIsVisible) {
                const value = typeof inputRelated === 'string' ? inputRelated : String(inputRelated)
                const showFormItem = await handleIsVisible(inputData.url, value)
                showFormItem.enabled ? setIsVisible('block') : setIsVisible('none')
            }
        }
        fetchData()
    }, [inputRelated, handleIsVisible, inputData.url])

    useEffect(() => {
        if (inputData.rules) {
            setConfigRules(
                inputData.rules.filter((rule) => {
                    const { required, optionalOnEdit } = rule
                    return !(required && optionalOnEdit && isUpdate)
                })
            )
        }
        if (inputData.validatorRule) {
            setConfigValidator(inputData.validatorRule)
        }
    }, [inputData.rules, inputData.validatorRule, isUpdate])

    useEffect(() => {
        const valueMatchValidator = ({ getFieldValue }: { getFieldValue: (key: string) => unknown }) => ({
            validator(_: unknown, value: unknown) {
                if (!value || getFieldValue(configValidator?.fieldName || '') === value) {
                    return Promise.resolve()
                }

                return Promise.reject(new Error(configValidator?.message))
            }
        })

        const allRules: FormRules = []
        allRules.push(...configRules)
        if (configValidator?.type === 'matchValue') {
            allRules.push(valueMatchValidator)
        }
        setFormRules(allRules)
    }, [configRules, configValidator])

    return (
        <Form.Item
            {...props}
            name={inputData.name}
            colon={false}
            label={hideLable ? '' : <ItemLabel labelData={inputData.label} note={inputData.note} />}
            rules={formRules}
            style={{ display: isVisible }}
        >
            {children}
        </Form.Item>
    )
}

export default FormItem
