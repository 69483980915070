import React, { FunctionComponent } from 'react'
import { Button } from 'antd'

interface SubmitButtonProps {
    label: string
    disabled: boolean
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({ label, disabled }) => (
    <Button type="primary" htmlType="submit" disabled={disabled}>
        {label}
    </Button>
)

export default SubmitButton
