import React, { FunctionComponent, Fragment, useState, useEffect } from 'react'
import { WysiwygInstance, WysiwygFactory } from '../../../../types'

export interface SimpleWysiwygProps {
    onChange?: (payload: string | undefined) => void
    value?: string
    wysiwygFactory?: WysiwygFactory
}

const SimpleWysiwyg: FunctionComponent<SimpleWysiwygProps> = ({ wysiwygFactory, value, onChange }) => {
    const [WysiwygEditor, setWysiwygEditor] = useState<WysiwygInstance>()

    useEffect(() => {
        const wysiwyg = wysiwygFactory?.()
        setWysiwygEditor(wysiwyg)
    }, [wysiwygFactory])

    if (!WysiwygEditor) return <Fragment></Fragment>
    return (
        <WysiwygEditor.component
            value={value}
            onChange={(e: unknown) => {
                onChange?.(WysiwygEditor.onChangeHandler(e))
            }}
        />
    )
}

export default SimpleWysiwyg
