import React, { FunctionComponent } from 'react'
import { InputData } from '../../../../types'
import FormItem from '../FormItem'
import CheckboxInput from './CheckboxInput'

interface CheckboxInputItemProps {
    data: InputData
}

const CheckboxInputItem: FunctionComponent<CheckboxInputItemProps> = ({ data, ...props }) => {
    return (
        <FormItem {...props} inputData={data} hideLable>
            <CheckboxInput disabled={data.disabled} label={data?.label?.text} note={data?.note} />
        </FormItem>
    )
}

export default CheckboxInputItem
