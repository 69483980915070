interface InputNamesDictionary {
    [inputName: string]: boolean
}

const isValidRecord = (item: unknown): boolean => {
    return typeof item === 'object'
}

export const generateInputNamesDictionary = (data: unknown[]): InputNamesDictionary => {
    const dictionary: InputNamesDictionary = {}

    const getNames = (list: unknown[]) => {
        list.forEach((inputConfig) => {
            if (!isValidRecord(inputConfig)) return
            const validInputConfig = inputConfig as {
                inputs: unknown[]
                name: string
                dynamicInputs: {
                    groupName: string
                }
            }

            if ('inputs' in validInputConfig) {
                getNames(validInputConfig.inputs)
            }

            if ('dynamicInputs' in validInputConfig) {
                dictionary[validInputConfig.dynamicInputs.groupName] = true
            }

            if ('name' in validInputConfig) {
                dictionary[validInputConfig.name] = true
            }
        })
    }

    getNames(data)

    return dictionary
}

interface ParsedFormValues {
    [key: string]: unknown
}

export const parseFormValues = (
    values: Record<string, unknown>,
    inputNamesDictionary: InputNamesDictionary
): ParsedFormValues => {
    function handleListValues(data: unknown[]): unknown[] {
        return data.map((value) => {
            if (value && typeof value === 'object') {
                const newValue: Record<string, unknown> = {}
                Object.entries(value).forEach(([key, objValue]) => {
                    if (inputNamesDictionary[key]) {
                        if (Array.isArray(objValue)) {
                            newValue[key] = handleListValues(objValue)
                        } else {
                            newValue[key] = objValue
                        }
                    }
                })
                return newValue
            }
            return value
        })
    }
    const postValues: ParsedFormValues = {}
    Object.entries(values).forEach(([key, value]) => {
        if (!Array.isArray(value)) {
            postValues[key] = value
        } else {
            postValues[key] = handleListValues(value)
        }
    })

    return postValues
}
