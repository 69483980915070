import React, { FunctionComponent } from 'react'
import { InputData, GetSelectOptions } from '../../../../types'

import FormItem from '../FormItem'
import { SelectInput } from '../../../SharedInputs'

interface SelectInputItemProps {
    data: InputData
    getFieldValue: (inputName: string) => unknown
    getSelectOptions?: GetSelectOptions
}

const SelectInputItem: FunctionComponent<SelectInputItemProps> = ({ data, ...props }) => (
    <FormItem {...props} inputData={data}>
        <SelectInput {...props} data={data} />
    </FormItem>
)

export default SelectInputItem
