import React, { FunctionComponent, Fragment } from 'react'
import { Col } from 'antd'
import { InputData } from '../../types'
import { InputsHelperProps } from '../interface'
import InputSwitch from './InputSwitch'
interface InputsGeneratorProps extends InputsHelperProps {
    data: InputData[]
}

const InputsGenerator: FunctionComponent<InputsGeneratorProps> = ({ data, ...props }) => {
    const getColSpan = (colSpan: InputData['colSpan']): number | undefined => {
        if (colSpan === 'auto') {
            return
        }
        if (typeof colSpan === 'number') {
            return colSpan
        }
        return 24
    }
    return (
        <Fragment>
            {data.map((inputData, i) => (
                <Col key={i} span={getColSpan(inputData.colSpan)}>
                    <InputSwitch {...props} data={inputData} />
                </Col>
            ))}
        </Fragment>
    )
}

export default InputsGenerator
