import React, { FunctionComponent } from 'react'
import SearchInput from './SearchInput'

interface PaginatedListTitleProps {
    title?: string
    placeholder?: string
    onFilterChange: (value: string) => void
}

const PaginatedListTitle: FunctionComponent<PaginatedListTitleProps> = ({
    title,
    placeholder,
    onFilterChange
}): JSX.Element => {
    return (
        <div className="paginated-list-card-head-title-container">
            <span>{title}</span>
            <SearchInput placeholder={placeholder} onChange={onFilterChange} />
        </div>
    )
}

export default PaginatedListTitle
